import curry from 'lodash/fp/curry';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';

import { StaticNavigationItem } from '@lumapps/community-navigation/types';
import { ItemVariant } from '@lumapps/lumx-navigation/constants';
import { SPACE_SERVICES_RECORD } from '@lumapps/spaces/constants';
import { spaceView } from '@lumapps/spaces/routes';
import { SpaceServices } from '@lumapps/spaces/types';

/**
 * Filters a list of services and transforms them to static navigation items
 *
 * @param {string} id
 * @param {string} slug
 * @param {boolean} replaceLabelWithTooltip
 * @param {SpaceServices[]} services
 * @param {SpaceServices[]} enabledServices
 */
export const servicesToNavigationItems = curry(
    (
        spaceId: string,
        spaceSlug: string,
        variant: ItemVariant | undefined,
        services: SpaceServices[],
    ): StaticNavigationItem[] =>
        map(
            pipe(
                (service: SpaceServices) => SPACE_SERVICES_RECORD[service],
                (record) => ({
                    ...record,
                    ...(variant ? { variant } : {}),
                    to: spaceView({ params: { id: spaceId, slug: spaceSlug, view: record.view } }),
                }),
            ),
            services,
        ),
);
