import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const rolesRoutes: Route = {
    path: '/admin/role',
    legacyId: 'app.admin.role',
    appId: AppId.legacy,
};

export const newRolesRoutes: Route = {
    path: '/admin/new/roles',
    legacyId: 'app.admin.role',
    appId: AppId.backOffice,
};

export const roles = (): Route => rolesRoutes;
