import React from 'react';

import { GenericEntityBlock } from '@lumapps/lumx-blocks/components/GenericEntityBlock';
import { mdiOpenInNew } from '@lumapps/lumx/icons';
import { ColorPalette, ColorVariant, Link, LinkProps, Text, Theme, Typography } from '@lumapps/lumx/react';
import { getPlayVideoRouteById } from '@lumapps/play-video/routes';
import { useViews } from '@lumapps/play/hooks/useViews';
import { formatDateForVideo, formatTime } from '@lumapps/play/utils';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';

import { usePlayerClassnames, useVideoMetadataVisibility } from '../../hooks';
import type { VisibleMetadata } from '../../types';
import { usePlayerContext } from '../Player';

import './Metadata.scss';

export interface MetadataProps {
    /** The params for the title */
    titleProps?: Partial<Pick<LinkProps, 'rightIcon' | 'target' | 'to' | 'typography'>>;
    /** Metadata to display */
    visibleMetadata?: VisibleMetadata;
}

export function Metadata({ visibleMetadata = [], titleProps }: MetadataProps) {
    const { element } = usePlayerClassnames();

    const {
        state: { video, videoId },
    } = usePlayerContext();

    const { linkProps: profileLinkProps } = useSocialProfileRoute({
        routeParams: { userId: video?.createdById },
        userData: { fullName: video?.createdByDetails?.fullName, id: video?.createdById || '' },
    });

    const videoViews = useViews({ views: video?.views || 0 });

    const {
        author: authorIsVisible,
        details: detailsAreVisible,
        duration: durationIsVisible,
        publicationDate: publicationDateIsVisible,
        title: titleIsVisible,
        views: viewsAreVisible,
    } = useVideoMetadataVisibility({ metadata: visibleMetadata });

    if (!visibleMetadata.length || !video) {
        return null;
    }

    const shouldDisplayAuthor = video.createdByDetails && authorIsVisible;
    const shouldDisplayVideoDetails = detailsAreVisible;

    const publishedAtDate =
        (video.status === 'published' || video.publicationStatus === 'published') && video.publishedAt;

    return (
        <div className={element('metadata')}>
            <GenericEntityBlock
                theme={Theme.dark}
                className={element('metadata__banner')}
                thumbnail={
                    shouldDisplayAuthor
                        ? {
                              className: element('metadata__video-author'),
                              alt: video.createdByDetails?.fullName || '',
                              image: video.createdByDetails?.profilePictureUrl || '',
                              linkAs: RouterLink,
                              linkProps: { ...profileLinkProps },
                          }
                        : undefined
                }
                title={
                    titleIsVisible && (
                        <Link
                            className={element('metadata__video-title')}
                            color={ColorPalette.light}
                            colorVariant={ColorVariant.N}
                            linkAs={RouterLink}
                            to={getPlayVideoRouteById(videoId)}
                            typography={Typography.title}
                            rightIcon={mdiOpenInNew}
                            {...titleProps}
                        >
                            {typeof video.title === 'string' ? video.title : video.title.value}
                        </Link>
                    )
                }
                metadata={
                    shouldDisplayVideoDetails
                        ? [
                              publicationDateIsVisible && (
                                  <GenericEntityBlock.Attribute
                                      value={formatDateForVideo({ date: publishedAtDate || video.createdAt })}
                                      key="date"
                                  />
                              ),
                              viewsAreVisible && <GenericEntityBlock.Attribute value={videoViews} key="views" />,
                          ].filter(Boolean)
                        : []
                }
            />

            {durationIsVisible && (
                <Text
                    as="span"
                    className={element('metadata__video-duration')}
                    color={ColorPalette.light}
                    typography={Typography.caption}
                    noWrap
                >
                    {formatTime(video.duration)}
                </Text>
            )}
        </div>
    );
}
