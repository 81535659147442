import React from 'react';

import noop from 'lodash/noop';

import { TextFieldProps } from '@lumapps/lumx/react';

import { ComboboxDispatch, ComboboxState, initialState } from '../ducks/reducer';
import { ComboboxProps, RegisteredComboboxOptionValue } from '../types';

export interface ComboboxContextActions {
    onSelect?: (option?: RegisteredComboboxOptionValue) => void;
    onInputChange?: TextFieldProps['onChange'];
    onOpen?: (params: { manual: boolean; currentValue: string }) => void;
}

export interface ComboboxContextProps extends ComboboxState, ComboboxContextActions {
    openOnFocus?: ComboboxProps['openOnFocus'];
    openOnClick?: ComboboxProps['openOnClick'];
    optionsLength: number;
    /** The dispatch function to manage the inner state */
    dispatch: ComboboxDispatch;
    /** The ids of the currently selected options */
    selectedIds?: Array<string>;
}

/** Context for the Combobox component */
export const ComboboxContext = React.createContext<ComboboxContextProps>({
    ...initialState,
    openOnFocus: false,
    openOnClick: false,
    optionsLength: 0,
    onSelect: noop,
    onInputChange: noop,
    onOpen: noop,
    dispatch: noop,
});

/** Context for a combobox section to store its unique id */
export const SectionContext = React.createContext<string>('');
