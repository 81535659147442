/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Orientation, Size, Text, Tooltip } from '@lumapps/lumx/react';
import { MovingFocusContext, useVirtualFocus } from '@lumapps/moving-focus';
import { preventDefault } from '@lumapps/utils/event/preventDefault';
import { PolymorphicComponentProps, PolymorphicRef } from '@lumapps/utils/types/PolymorphicComponent';

import { ComboboxOptionIdProvider } from '../../context/ComboboxOptionContext';
import { useCombobox } from '../../hooks/useCombobox';
import { useComboboxSectionId } from '../../hooks/useComboboxSectionId';
import { useRegisterOption } from '../../hooks/useRegisterOption';
import { ComboboxOptionProps, RegisteredComboboxOptionValue } from '../../types';
import { generateOptionId, isStringOptionComponent } from '../../utils';

import './index.scss';

export const CLASSNAME = 'combobox-option';
export const LUMX_CLASSNAME = 'lumx-list-item';

type OptionContentProps<O extends object = any> = Omit<
    ComboboxOptionProps<O>,
    'id' | 'textValue' | 'filterFromInput' | 'data'
> & {
    id: string;
    isSelected?: boolean;
    onSelect?(): void;
};

/**
 * Content of the option.
 * This should only be rendered if the option is shown.
 */
const OptionContent = React.forwardRef(
    <O extends object = any, C extends React.ElementType = 'span'>(
        {
            id,
            onSelect,
            isSelected,
            isDisabled,
            children,
            className,
            before,
            after,
            as,
            size = Size.tiny,
            comboboxType,
            tooltipProps,
            ...forwardedProps
        }: PolymorphicComponentProps<C, OptionContentProps<O>>,
        ref?: PolymorphicRef<C>,
    ) => {
        const { block, element } = useClassnames(CLASSNAME);
        const lumxListItem = useClassnames(LUMX_CLASSNAME);
        const itemRef = React.useRef<HTMLLIElement>(null);
        const { state } = React.useContext(MovingFocusContext);
        const { selectedIds } = useCombobox();
        const hasSelection = selectedIds !== undefined;
        const isHighlighted = useVirtualFocus(id, itemRef, false, comboboxType === 'grid' ? id : undefined);

        const Element = as || 'span';
        const ariaSelected = isSelected ? 'true' : 'false';

        /**
         * The DS `ListItem` component has a lot of behavior / default props we do not want here.
         * Notably the before/after items are within the interactive element, which we do not want.
         * So we use a native li tag.
         */
        return (
            <li
                className={block(undefined, [className, lumxListItem.block({ [`size-${size}`]: !!size })])}
                role="presentation"
                ref={itemRef}
            >
                <div
                    role={comboboxType === 'grid' ? 'row' : 'presentation'}
                    className={element(
                        'content',
                        {
                            // Not using the lumx list item disabled style as it blocks pointer events
                            'is-disabled': !!isDisabled,
                        },
                        lumxListItem.element('link', {
                            'is-selected': Boolean(isSelected),
                            'is-highlighted': Boolean(isHighlighted),
                        }),
                    )}
                    data-focus-visible-added={state.isUsingKeyboard && isHighlighted ? 'true' : undefined}
                >
                    <ComboboxOptionIdProvider optionId={id}>
                        <Tooltip forceOpen={state.isUsingKeyboard && isHighlighted} closeMode="hide" {...tooltipProps}>
                            <Element
                                id={id}
                                className={element('trigger')}
                                role={comboboxType === 'grid' ? 'gridcell' : 'option'}
                                aria-selected={hasSelection ? ariaSelected : undefined}
                                aria-disabled={isDisabled}
                                onClick={onSelect}
                                // Prevent mouse down to avoid blur before the click is activated
                                onMouseDown={preventDefault}
                                ref={ref}
                                {...forwardedProps}
                            >
                                {before && (
                                    <Text
                                        as="span"
                                        role="presentation"
                                        className={element('before', undefined, lumxListItem.element('before'))}
                                    >
                                        {before}
                                    </Text>
                                )}
                                {children}
                            </Element>
                        </Tooltip>
                        {after && (
                            <FlexBox
                                orientation={Orientation.horizontal}
                                gap={Size.regular}
                                role="presentation"
                                className={element('after', undefined, lumxListItem.element('after'))}
                            >
                                {after}
                            </FlexBox>
                        )}
                    </ComboboxOptionIdProvider>
                </div>
            </li>
        );
    },
);

/**
 * Option to set within a combobox list.
 *
 * @family Combobox
 * @param ComboboxOptionProps
 * @returns ComboboxOption
 */
export const ComboboxOption = React.forwardRef(
    <O extends object = any, C extends React.ElementType = 'span'>(
        props: PolymorphicComponentProps<C, ComboboxOptionProps<O>>,
        ref: PolymorphicRef<C>,
    ) => {
        const {
            children,
            id,
            textValue,
            data,
            filterFromInput = true,
            onSelect: onOptionSelect,
            isDisabled,
            as = 'span',
            ...optionProps
        } = props;

        // Get the id of the current group, if any.
        const groupId = useComboboxSectionId();
        const { comboboxId, selectedIds, showAll, inputValue, handleSelected, type } = useCombobox();
        // Generate a unique id for this option.
        const generatedId = generateOptionId(comboboxId, id);

        const isSelected = selectedIds?.includes(generatedId);

        // If no text value is set and the direct child is a simple string, use it as value.
        const value = children && !textValue && isStringOptionComponent(props) ? children.toString() : textValue;

        const showOption =
            !filterFromInput ||
            showAll ||
            value
                ?.toString()
                ?.toLowerCase()
                .includes(inputValue?.toLowerCase());

        const registeredOption: RegisteredComboboxOptionValue = React.useMemo(
            () => ({
                id,
                generatedId,
                textValue: value,
                data,
                filter: filterFromInput,
                isDisabled: isDisabled || !showOption,
                sectionId: groupId,
                onSelect: onOptionSelect,
            }),
            [data, filterFromInput, generatedId, groupId, id, isDisabled, onOptionSelect, showOption, value],
        );

        // Register the option
        useRegisterOption(generatedId, registeredOption, showOption);

        const handleSelect = React.useCallback(
            () => handleSelected(registeredOption, 'click'),
            [handleSelected, registeredOption],
        );

        if (!id || !showOption) {
            return null;
        }

        return (
            <OptionContent
                id={generatedId}
                onSelect={handleSelect}
                isSelected={isSelected}
                isDisabled={isDisabled}
                as={as as React.ElementType}
                ref={ref}
                comboboxType={type}
                {...optionProps}
            >
                {children || textValue}
            </OptionContent>
        );
    },
);
