import React from 'react';

import find from 'lodash/fp/find';
import getOr from 'lodash/fp/getOr';
import matchesProperty from 'lodash/fp/matchesProperty';

import { margin, useClassnames } from '@lumapps/classnames';
import { SPACE_VISIBILITIES } from '@lumapps/communities/constants';
import { CommunityThumbnailDisplayType } from '@lumapps/communities/types';
import { isFeatureEnabled } from '@lumapps/features';
import {
    FlexBox,
    Text,
    Heading,
    Icon,
    Typography,
    GenericBlock,
    Thumbnail,
    ThumbnailVariant,
} from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { SpaceNavigation } from '@lumapps/spaces-navigation/components/SpaceNavigation';
import {
    SubscriptionsActions,
    SubscriptionsActionsProps,
} from '@lumapps/subscriptions/components/SubscriptionsActions';
import { SubscriptionsTypes } from '@lumapps/subscriptions/types';
import { useTranslate } from '@lumapps/translations';
import { SOCIAL_FEATURE_TOKEN } from '@lumapps/user/constants';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';

import { spaceToCommunityVisibilityMap } from '../../constants';
import { selectIsMembershipRequestLoading } from '../../ducks/selectors';
import { SpaceData } from '../../types';
import { SpaceAboutDialog } from '../SpaceAboutDialog';
import { SpaceAccessRequest } from '../SpaceAccessRequest';

import './index.scss';

interface SpaceHeaderProps extends SpaceData {
    spaceId: string;
    isPreview?: boolean;
    isNavigationDisplayed?: boolean;
}

/**
 * Space header, shown on all pages of the Space feature.
 * */
export const SpaceHeader: React.FC<SpaceHeaderProps> = ({
    headerDisplayMode,
    enabledServices,
    title,
    coverImage,
    visibility,
    folksonomyTags,
    myMembership,
    myPermissions,
    mySubscriptions,
    subscriptionSettings,
    spaceId,
    description,
    isPreview = false,
    isNavigationDisplayed = true,
}) => {
    const { block, element } = useClassnames('space-header');
    const { isMobile } = useResponsive();
    const { translateObject, translateKey } = useTranslate();
    const isMembershipRequestLoading = useSelector(selectIsMembershipRequestLoading);
    const isInWebview = useDetectMobileAppWebView();
    const isSocialEnabled = useSelector(isFeatureEnabled(SOCIAL_FEATURE_TOKEN));

    if (!title || !visibility || !myPermissions || !mySubscriptions || !enabledServices || isInWebview) {
        return null;
    }

    const visibilityObject = find(matchesProperty('id', spaceToCommunityVisibilityMap[visibility]), SPACE_VISIBILITIES);

    const subscription: SubscriptionsActionsProps['subscription'] = {
        resourceId: spaceId,
        resourceType: SubscriptionsTypes.content,
        autoFetch: true,
        enableNotification: true,
        isSubscribed: getOr(false, 'isSubscribed', mySubscriptions),
        isNotified: getOr(false, 'isNotified', mySubscriptions),
        areNotificationsEnabledOnSubscription: getOr(
            false,
            'areNotificationsEnabledOnSubscription',
            subscriptionSettings,
        ),
    };

    return (
        <FlexBox
            orientation="vertical"
            gap="big"
            as="header"
            className={block({ preview: isPreview }, margin('bottom', 'big'))}
        >
            {headerDisplayMode === CommunityThumbnailDisplayType.cover && !!coverImage && (
                <div className={element('banner')}>
                    <Thumbnail
                        fillHeight
                        variant={ThumbnailVariant.rounded}
                        aspectRatio="horizontal"
                        alt=""
                        image={coverImage.url}
                        focusPoint={coverImage.focalPoint}
                    />
                </div>
            )}
            <GenericBlock gap="huge" hAlign="center" className={margin('bottom', 'regular')} wrap>
                {headerDisplayMode === CommunityThumbnailDisplayType.small && !!coverImage && (
                    <GenericBlock.Figure>
                        <Thumbnail
                            variant={ThumbnailVariant.rounded}
                            size="l"
                            aspectRatio="square"
                            alt=""
                            image={coverImage.url}
                            focusPoint={coverImage.focalPoint}
                        />
                    </GenericBlock.Figure>
                )}
                <GenericBlock.Content className={element('content')}>
                    <Heading
                        as="h1"
                        typography={isMobile ? Typography.custom.title3 : Typography.custom.title1}
                        className={element('title')}
                    >
                        {translateObject(title)}
                    </Heading>

                    {visibilityObject && (
                        <Text as="p" color="dark" colorVariant="L2" typography={Typography.body1}>
                            <Icon icon={visibilityObject.icon} />
                            {translateKey(visibilityObject.title)}
                        </Text>
                    )}
                </GenericBlock.Content>
                <GenericBlock.Actions
                    gap="regular"
                    vAlign="right"
                    noShrink
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                >
                    <FlexBox orientation="horizontal" gap="regular" noShrink>
                        <SpaceAboutDialog
                            title={title}
                            description={description}
                            visibility={visibility}
                            folksonomyTags={folksonomyTags}
                            isMobile={isMobile}
                        />
                        {(subscription.isSubscribed || myPermissions.canSubscribe) && isSocialEnabled && (
                            <SubscriptionsActions scope="space-subscription" subscription={subscription} />
                        )}
                    </FlexBox>
                    <SpaceAccessRequest
                        canRequest={myPermissions.canRequestMembership}
                        isRequestLoading={isMembershipRequestLoading}
                        requestStatus={myMembership?.requestDetails?.status}
                    />
                </GenericBlock.Actions>
            </GenericBlock>
            {isNavigationDisplayed && <SpaceNavigation enabledServices={enabledServices} />}
        </FlexBox>
    );
};
