import React, { useMemo } from 'react';

import { RenderingType } from '@lumapps/communities/types';
import { CommunityNavigation } from '@lumapps/community-navigation';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { ViewMode } from '@lumapps/lumx-navigation/constants';
import { useSelector } from '@lumapps/redux/react';
import { useParams } from '@lumapps/router';
import { isSpaceEditableByUser } from '@lumapps/spaces/ducks/selectors';
import { SpaceViews, type SpaceRouteParameters, type SpaceServices } from '@lumapps/spaces/types';

import { isSpaceNavigationV2Enabled } from '../../ducks/selectors';
import { useSpaceNavigationItems } from '../../hooks/useSpaceNavigationItems';

export interface SpaceNavigationProps {
    enabledServices: SpaceServices[];
}

export const SpaceNavigation: React.FC<SpaceNavigationProps> = ({ enabledServices }) => {
    const { id, slug, view } = useParams<SpaceRouteParameters>();
    const isManager = useSelector(isSpaceEditableByUser);
    const instanceId = useSelector(instanceIdSelector);
    const isV2 = useSelector(isSpaceNavigationV2Enabled);

    const { exploreItems, staticItems } = useSpaceNavigationItems({ id, slug, enabledServices, isV2 });

    const communityNavigationQuery = useMemo(
        () => ({
            siteId: instanceId,
            communityId: id,
        }),
        [id, instanceId],
    );

    return (
        <CommunityNavigation
            currentView={view as SpaceViews}
            staticItems={staticItems}
            exploreItems={exploreItems}
            theme="light"
            viewMode={ViewMode.horizontal}
            scope={RenderingType.space}
            isManager={isManager}
            communityNavigationQuery={communityNavigationQuery}
        />
    );
};
