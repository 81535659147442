import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { Button, Text, Tooltip } from '@lumapps/lumx/react';
import { useId } from '@lumapps/utils/hooks/useId';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { PolymorphicComponentProps } from '@lumapps/utils/types/PolymorphicComponent';

import { useComboboxRefs } from '../../context/ComboboxRefsContext';
import { useCombobox } from '../../hooks/useCombobox';
import { useComboboxButton } from '../../hooks/useComboboxButton';

export type ComboboxButtonProps = {
    label: string;
    onFocus?: React.FocusEventHandler;
    onBlur?: React.FocusEventHandler;
};

/**
 * Combobox button trigger.
 *
 * @family Combobox
 */
export const ComboboxButton = React.forwardRef(
    <C extends React.ElementType = typeof Button>(
        props: PolymorphicComponentProps<C, ComboboxButtonProps>,
        ref: React.ComponentPropsWithRef<C>['ref'],
    ) => {
        const { as, label, onFocus, onBlur, ...forwardedProps } = props;
        const refs = useComboboxRefs();
        const context = useCombobox();
        const buttonProps = useComboboxButton({ context, refs, onBlur, onFocus });

        const Element = as || Button;
        const labelId = useId();

        // Show a tooltip when the label is obscured by the selected value and when the combobox is not open
        const tooltipLabel = context.inputValue && !context.isOpen ? label : null;

        return (
            <>
                <Text as="span" id={labelId} className={visuallyHidden()}>
                    {label}
                </Text>
                <Tooltip label={tooltipLabel}>
                    <Element
                        ref={mergeRefs([ref, refs.triggerRef, refs.anchorRef]) as any}
                        aria-labelledby={labelId}
                        {...forwardedProps}
                        {...buttonProps}
                    >
                        {context.inputValue || (
                            <Text as="span" aria-hidden>
                                {label}
                            </Text>
                        )}
                    </Element>
                </Tooltip>
            </>
        );
    },
);
