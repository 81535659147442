import React from 'react';

import { articleView } from '@lumapps/articles/routes';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { isSpacesFeatureEnabled } from '@lumapps/communities/ducks/selectors';
import { CommunityLinkRef } from '@lumapps/communities/types';
import { getCommunityRoute } from '@lumapps/communities/utils/community-link/getCommunityRoute';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { useHomeRoute } from '@lumapps/contents/hooks/useHomeRoute';
import { ContentLinkRef } from '@lumapps/contents/types';
import { getEventLink } from '@lumapps/events/utils/getEventLink';
import { trainingView } from '@lumapps/learning-trainings/routes';
import { ButtonProps, ColorPalette, LinkProps } from '@lumapps/lumx/react';
import { microAppByIdFrontOffice } from '@lumapps/micro-apps/routes';
import { getPlaylistRouteById } from '@lumapps/play-playlists/routes';
import { getPlayVideoRouteById } from '@lumapps/play-video/routes';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../../../constants';
import { Resource } from '../../../types';

type StrictTarget = {
    // needed because Button props are stricter than "string"
    target?: ButtonProps['target'];
};

type InnerLinkProps = LinkProps & StrictTarget;
type HtmlLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
    StrictTarget & {
        // needed because Button props are stricter than "string"
        type?: React.ButtonHTMLAttributes<any>['type'];
        color?: ColorPalette;
    };

export type UseResourceLink =
    // Special community link (handles auto redirect to v1 or v2)
    | { linkAs: typeof CommunityLink; to: CommunityLinkRef }
    // Special content link (handles auto redirect to v1 or v2)
    | { linkAs: typeof ContentLink; to: ContentLinkRef }
    | InnerLinkProps
    | HtmlLinkProps
    | undefined;

/**
 * Generates a props object for Link or Button
 * @param resource - data for a link preview with lumapps internal resource
 * @returns UseResourceLink
 */
export const useResourceLinkProps = (resource?: Resource, url?: string): UseResourceLink => {
    const isSpaceFeatureEnabled = useSelector(isSpacesFeatureEnabled);
    const homeRoute = useHomeRoute();

    switch (resource?.type) {
        case LINK_PREVIEW_RESOURCE_TYPES.ARTICLE: {
            return { linkAs: RouterLink, to: articleView(resource?.id || '') };
        }
        case LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY:
            return {
                linkAs: CommunityLink,
                to: {
                    id: resource.id,
                    slug: resource.slug,
                    instance: { slug: resource.siteSlug },
                },
            };
        case LINK_PREVIEW_RESOURCE_TYPES.CONTENT:
            return {
                linkAs: ContentLink,
                to: {
                    id: resource.id,
                    slug: resource.slug,
                    instance: { slug: resource.siteSlug },
                },
            };
        case LINK_PREVIEW_RESOURCE_TYPES.EVENT:
            return getEventLink(resource?.id || '', undefined, undefined, { slug: resource?.siteSlug });
        case LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP:
            return { linkAs: RouterLink, to: microAppByIdFrontOffice({ id: resource?.id || '' }) };
        case LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH:
        case LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE:
            return { linkAs: RouterLink, to: trainingView(resource?.id || '') };
        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO:
            return { linkAs: RouterLink, to: getPlayVideoRouteById(resource?.id || '') };
        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST:
            return { linkAs: RouterLink, to: getPlaylistRouteById({ id: resource?.id || '' }) };
        case LINK_PREVIEW_RESOURCE_TYPES.POST:
            return {
                linkAs: RouterLink,
                to: getCommunityRoute(
                    {
                        id: resource.communityId,
                        slug: resource.communitySlug,
                        identifier: resource.id,
                        view: 'post',
                        instance: { id: resource.siteSlug, slug: resource.siteSlug },
                    },
                    { isSpaceFeatureEnabled, homeRoute },
                ).route,
            };
        default:
            return {
                linkAs: 'a',
                href: url || '',
                target: '_blank',
                rel: 'noopener noreferrer',
            };
    }
};
