export enum ROLES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244404
     */
    ROLES = 'ADMIN.NAV.ROLES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452340
     */
    AUTHORIZATIONS = 'ADMIN_ROLE_TAB_AUTHORIZATIONS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452334
     */
    DELETE_ROLE = 'ADMIN_ROLE_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452335
     */
    DELETE_ROLE_DESCRIPTION = 'ADMIN_ROLE_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452336
     */
    DELETE_ROLE_SUCCESS = 'ADMIN_ROLE_DELETE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452339
     */
    ROLE_SAVE_SUCCESS = 'ADMIN_ROLE_SAVE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452330
     */
    CAN_EDIT_ONLY_OWN_CONTENT = 'ADMIN_ROLE_AUTHORIZATION_CAN_EDIT_ONLY_OWN_CONTENT',
}
