import React from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { isCancel } from '@lumapps/base-api/utils/isCancel';
import { DEBOUNCE_DELAY } from '@lumapps/constants/config';
import { cancelListFeed, listFeed, ListFeedParams } from '@lumapps/groups/api';
import { GROUPS_PER_FETCH } from '@lumapps/groups/constants';
import { Group } from '@lumapps/groups/types';
import { FieldLoadingState } from '@lumapps/lumx-states/components/FieldLoadingState';
import { mdiAccountPlus } from '@lumapps/lumx/icons';
import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteMultiple,
    AutocompleteMultipleProps,
    TextProps,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { USER } from '@lumapps/user/keys';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { actions, initialState, reducer, SearchGroupsStatus } from '../../ducks/slice';
import { GroupPickerFieldSearchResults } from './GroupPickerFieldSearchResults';

export interface GroupPickerFieldProps {
    /** Whether we should focus to the GroupPickerField on init */
    focusOnInit?: boolean;
    /** Whether we should fetch the group list */
    searchOnMount?: boolean;
    /** Error message to show if there is an error */
    errorMessage?: string;
    /** Whether the group picker is disabled */
    isDisabled?: boolean;
    /** Whether the group picker field is required */
    isRequired?: boolean;
    /** Maximum results per search (default 10) */
    maxResults?: number;
    /**  optional icon for the picker */
    icon?: any;
    /** The language to search and display the results */
    language?: string;
    /** Whether we should search the platform groups or the current instance. Default to false */
    searchInPlatform?: boolean;
    /** The instance in which to search if we search in site */
    instance?: string;
    /**
     * Custom params that can be added to the search query for specific searches.
     * PLEASE NOTE when passing this props, use a memoized object or else a fetch will be triggered at every remount
     */
    customSearchParams?: Partial<ListFeedParams>;
    /** group named all */
    groupAll?: Group;
    /** group named public */
    groupPublic?: Group;
    /** whether the All group should be displayed */
    displayAllGroup?: boolean;
    /** whether the Public group should be displayed */
    displayPublicGroup?: boolean;
    /** The callback when a group is selected */
    onGroupSelected(group: Group): void;
    /** function to add text or an icon after a search result item (e.g. already added) */
    getGroupAfter?(group: Group): React.ReactElement | null;
    /** optional callback called when the suggestion list is opened */
    onOpen?(): void;
    /** optional callback called when the suggestion list is closed */
    onClose?(): void;
    /** optional placeholder to be displayed on the picker */
    placeholder?: string;
    /** label to be displayed on the picker */
    label?: AutocompleteProps['label'];
    /** whether it should be a multiple autocomplete or not */
    isMultiple?: boolean;
    /** selected values to be displayed on the autocomplete. Only works when isMultiple=true */
    values?: AutocompleteMultipleProps['values'];
    /** renderer for selected values for the autocomplete. Only works when isMultiple=true  */
    selectedChipRender?: AutocompleteMultipleProps['selectedChipRender'];
    /** Optional helper text. Has priority over `showHelper` props */
    helper?: string;
    /** whether to display the autocomplete helper or not */
    showHelper?: boolean;
    /** optional className for the picker */
    className?: string;
    /** should we close suggestions on select */
    shouldCloseOnSelect?: boolean;
    /** custom base class used in list */
    pickerClassName?: string;
    /** optional Callback to determine if a search result should be disabled */
    isGroupDisabled?: (group: Group) => boolean;
    /** whether suggestions should be refreshed when opening them */
    refreshSuggestionsOnOpen?: boolean;
    /**
     * prop that should be used when you do not have the names of the groups selected.
     * With this prop in combination with `onDefaultGroupsFetch`, the groups will be fetched
     * and the names will be retrieved so that they can be displayed correctly on the picker.
     */
    defaultValuesIds?: string[];
    /**
     * Callback to be executed for the `defaultValuesIds` provided. This will be executed once
     * the component is mounted, and it will return the groups for the ids provided in `defaultValuesIds`.
     * Once the request is successful, it will execute this callback so that the component that
     * is using the GroupPickerField can update the data.
     */
    onDefaultGroupsFetch?: (groups: Group[]) => void;
    /** optional autocomplete width */
    fitToAnchorWidth?: AutocompleteMultipleProps['fitToAnchorWidth'];
    /** defines if the clear button appears */
    hasClearButton?: boolean;
    /**
     * Whether a loading state should be used while loading the default ids
     */
    shouldDisplayLoadingState?: boolean;
    /** additional text field properties */
    textFieldProps?: Partial<AutocompleteProps['textFieldProps']>;
    /** optional props of the result Text component * */
    resultsTextProps?: Partial<TextProps>;
}

export interface SearchGroupsParams {
    searchTerm: string;
    cursor: string;
    loadMore?: boolean;
    maxResults?: number;
    restrictToFeeds?: ListFeedParams['restrictToFeeds'];
}

/**
 * Component that displays a picker for groups displayed in an autocomplete fashion. This component does not
 * manage the display of selected groups, so you will need to manage them on your end. If you are displaying chips
 * inside the group picker for the selected groups, please use the `GroupChipPicker` instead.
 * @family Pickers
 * @param GroupPickerFieldProps
 * @returns GroupPickerField
 */
export const GroupPickerField: React.FC<GroupPickerFieldProps> = ({
    focusOnInit,
    errorMessage,
    isDisabled,
    isGroupDisabled,
    isRequired,
    maxResults,
    language,
    searchInPlatform = false,
    instance,
    customSearchParams,
    displayPublicGroup = true,
    displayAllGroup = true,
    searchOnMount = true,
    getGroupAfter,
    onGroupSelected,
    onOpen,
    onClose,
    label,
    isMultiple,
    selectedChipRender,
    values,
    helper,
    showHelper = true,
    className,
    shouldCloseOnSelect = true,
    pickerClassName,
    icon = mdiAccountPlus,
    placeholder,
    groupAll,
    groupPublic,
    refreshSuggestionsOnOpen = false,
    defaultValuesIds = [],
    onDefaultGroupsFetch,
    fitToAnchorWidth = true,
    hasClearButton = true,
    shouldDisplayLoadingState = false,
    textFieldProps = {},
    resultsTextProps,
}: GroupPickerFieldProps) => {
    const { translateKey } = useTranslate();
    const [defaultFetchedStatus, setDefaultFetchedStatus] = React.useState(BaseLoadingStatus.initial);

    // Used to control the text input
    const inputRef = React.useRef<HTMLInputElement>(null);

    // The key that will be used to cancel calls. Is unique per instance of the component
    const SEARCH_GROUPS_KEY = React.useMemo(() => `searchGroupsKey${generateUUID()}`, []);
    const [{ searchTerm, searchGroupsStatus, searchGroupsResults, showSuggestions }, dispatch] = React.useReducer(
        reducer,
        initialState,
    );

    // The function that will call the api to fetch the (groups)
    const listGroups = React.useCallback(
        async (params: ListFeedParams, loadMore = false) => {
            try {
                const response = await listFeed(params, SEARCH_GROUPS_KEY);

                // Add the results to the state. items are filtered to remove any group we don't want to show, if any
                const actionPayload = {
                    cursor: response.data?.cursor,
                    more: response.data?.more,
                    items: response.data?.items || [],
                };
                dispatch(
                    loadMore
                        ? actions.addSearchGroupsResult(actionPayload)
                        : actions.setSearchGroupsResult(actionPayload),
                );
                dispatch(actions.setSearchGroupsStatus(SearchGroupsStatus.loaded));
            } catch (exception) {
                // If the call has been canceled, we keep the loading state, else return error state
                if (!isCancel(exception)) {
                    // Gracefully catch errors for now by giving 0 results
                    dispatch(
                        actions.setSearchGroupsResult({
                            cursor: '',
                            more: false,
                            items: [],
                        }),
                    );
                    dispatch(actions.setSearchGroupsStatus(SearchGroupsStatus.error));
                }
            }
        },
        [SEARCH_GROUPS_KEY],
    );

    // listGroups is debounced so the call is not done every time the user types
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedListGroups = React.useCallback(debounce(listGroups, DEBOUNCE_DELAY, { leading: true }), []);

    // Function called by the component to update the searchGroupStatus and call the debounced ListFeed
    const searchGroups = React.useCallback(
        async (searchGroupsParams: SearchGroupsParams, shouldCancel = true) => {
            dispatch(
                actions.setSearchGroupsStatus(
                    searchGroupsParams.loadMore ? SearchGroupsStatus.loadingMore : SearchGroupsStatus.loading,
                ),
            );

            const exceptFeeds = customSearchParams?.exceptFeeds || [];

            if (!displayAllGroup && groupAll) {
                exceptFeeds.push(groupAll.uid || groupAll.id);
            }

            if (!displayPublicGroup && groupPublic) {
                exceptFeeds.push(groupPublic.uid || groupPublic.id);
            }

            const params: ListFeedParams = {
                query: searchGroupsParams.searchTerm,
                maxResults: (searchGroupsParams.maxResults || GROUPS_PER_FETCH).toString(),
                lang: language,
                cursor: searchGroupsParams.cursor,
                exceptFeeds,
                ...customSearchParams,
            };

            // If we don't search groups at the platform level, add the instance id to the request
            if (!searchInPlatform) {
                params.instance = instance;
            }

            if (shouldCancel) {
                cancelListFeed(SEARCH_GROUPS_KEY);
            }
            await debouncedListGroups(params, searchGroupsParams.loadMore);
        },
        [
            SEARCH_GROUPS_KEY,
            customSearchParams,
            debouncedListGroups,
            displayAllGroup,
            displayPublicGroup,
            groupAll,
            groupPublic,
            instance,
            language,
            searchInPlatform,
        ],
    );

    // The list of result groups
    const groups = searchGroupsResults?.items;

    // Fetch for the groups on first loading
    React.useEffect(() => {
        if (searchGroupsStatus === SearchGroupsStatus.initial && focusOnInit) {
            // Automatically focus in the Item picker
            // eslint-disable-next-line no-unused-expressions
            inputRef?.current?.focus();
        }
    }, [focusOnInit, searchGroupsStatus]);

    const triggerSearch = React.useCallback(
        (canCancel = true, query = '') => {
            searchGroups(
                {
                    searchTerm: query || searchTerm,
                    cursor: '',
                    maxResults,
                },
                canCancel,
            );
        },
        [searchGroups, searchTerm, maxResults],
    );

    React.useEffect(() => {
        if (focusOnInit && searchOnMount && searchGroupsStatus === SearchGroupsStatus.initial) {
            triggerSearch(false);
        }
    }, [triggerSearch, searchOnMount, searchGroupsStatus, focusOnInit]);

    // If the searchTerm or searchGroups have changed, re-trigger a fetch to get the new results
    React.useEffect(() => {
        triggerSearch(true, searchTerm);
    }, [searchTerm, triggerSearch]);

    /**
     * Retrieve the groups for the provided `defaultValuesIds`
     */
    React.useEffect(() => {
        if (
            defaultValuesIds &&
            defaultValuesIds.length > 0 &&
            onDefaultGroupsFetch &&
            defaultFetchedStatus === BaseLoadingStatus.initial
        ) {
            const params: ListFeedParams = {
                restrictToFeeds: defaultValuesIds,
                lang: language,
                excludeSegments: customSearchParams?.excludeSegments ?? true,
                // Should override maxResults because backend default to 10
                maxResults: defaultValuesIds?.length.toString(),
            };

            if (!searchInPlatform) {
                params.instance = instance;
            }

            setDefaultFetchedStatus(BaseLoadingStatus.loading);

            listFeed(params)
                .then((response) => {
                    const { data } = response;
                    onDefaultGroupsFetch(data.items);
                })
                .catch((exception) => {
                    if (!isCancel(exception)) {
                        /**
                         * If the request fails, we return the groups but instead of displaying the name
                         * we use the id as the name. It won't be pretty but at least the user will be
                         * able to use the component.
                         */
                        onDefaultGroupsFetch(
                            defaultValuesIds.map((id) => {
                                return {
                                    id,
                                    name: id,
                                    uid: id,
                                };
                            }),
                        );
                    }
                })
                .finally(() => {
                    setDefaultFetchedStatus(BaseLoadingStatus.idle);
                });
        }
    }, [
        defaultValuesIds,
        language,
        onDefaultGroupsFetch,
        onGroupSelected,
        searchInPlatform,
        instance,
        customSearchParams,
        defaultFetchedStatus,
    ]);

    /** Cleanup debounce and ongoing calls on unmount */
    React.useEffect(() => {
        return () => {
            debouncedListGroups?.cancel?.();
            cancelListFeed(SEARCH_GROUPS_KEY);
        };
    }, [SEARCH_GROUPS_KEY, debouncedListGroups]);

    /** Function called when the suggestion list is opened */
    const onOpenSuggestions = React.useCallback(() => {
        if (onOpen) {
            onOpen();
        }
        dispatch(actions.setShowSuggestions(true));
        // Trigger a fetch on the first opening
        if (searchGroupsStatus === SearchGroupsStatus.initial || refreshSuggestionsOnOpen) {
            searchGroups({
                searchTerm,
                cursor: '',
                maxResults,
            });
        }
    }, [maxResults, onOpen, refreshSuggestionsOnOpen, searchGroups, searchGroupsStatus, searchTerm]);

    /** Function called when the suggestion list is closed */
    const onCloseSuggestions = () => {
        if (onClose) {
            onClose();
        }

        dispatch(actions.setShowSuggestions(false));
        dispatch(actions.setsearchTerm(''));
    };

    /**
     * Function called when the search input has changed
     * If we set to empty string, close the suggestion list and empty it
     */
    const onChange = (newSearchTerm: string) => {
        dispatch(actions.setsearchTerm(newSearchTerm));

        // If we search for nothing (=clearing searchbox), close the suggestionbox. It will open with on next focus
        // with the default list
        if (newSearchTerm === '' && shouldCloseOnSelect) {
            onCloseSuggestions();
        } else if (!showSuggestions) {
            onOpenSuggestions();
        }

        if (!shouldCloseOnSelect && inputRef?.current) {
            inputRef.current.focus();
        }
    };

    // Function called when an item is selected. Calls onGroupSelected and reset the query
    const handleGroupSelected = (group: Group) => {
        onGroupSelected(group);

        if (shouldCloseOnSelect) {
            onChange('');
        }
    };

    const AutocompleteComponent = isMultiple ? AutocompleteMultiple : Autocomplete;

    const onKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Tab') {
            onCloseSuggestions();
        }
    };

    if (defaultFetchedStatus === BaseLoadingStatus.loading && shouldDisplayLoadingState) {
        return <FieldLoadingState />;
    }

    return (
        <AutocompleteComponent
            placeholder={isEmpty(values) ? placeholder : undefined}
            value={searchTerm}
            isOpen={showSuggestions}
            isDisabled={isDisabled}
            onChange={onChange}
            icon={icon}
            clearButtonProps={hasClearButton ? { label: translateKey(GLOBAL.CLEAR) } : undefined}
            label={label || translateKey(GLOBAL.ADD_GROUPS)}
            closeOnClickAway
            closeOnEscape
            focusAnchorOnClose={false}
            onFocus={onOpenSuggestions}
            onClose={onCloseSuggestions}
            inputRef={inputRef}
            helper={helper || showHelper ? translateKey(helper || USER.SELECT_GROUP_PRESS_ENTER) : undefined}
            className={className}
            hasError={Boolean(errorMessage)}
            error={errorMessage}
            isRequired={isRequired}
            selectedChipRender={selectedChipRender as any}
            values={values || []}
            onKeyDown={onKeyDown}
            onInfiniteScroll={() => {
                if (searchGroupsStatus === SearchGroupsStatus.loaded && searchGroupsResults.more) {
                    searchGroups({
                        searchTerm,
                        cursor: searchGroupsResults.cursor || '',
                        loadMore: true,
                        maxResults,
                    });
                }
            }}
            fitToAnchorWidth={fitToAnchorWidth}
            textFieldProps={textFieldProps}
        >
            <GroupPickerFieldSearchResults
                inputRef={inputRef}
                groups={groups}
                isGroupDisabled={isGroupDisabled}
                searchGroupsStatus={searchGroupsStatus}
                searchTerm={searchTerm}
                onSearchRetry={() => {
                    searchGroups({
                        searchTerm,
                        cursor: searchGroupsResults.cursor || '',
                        maxResults,
                    });
                }}
                onSearchClear={() => onChange('')}
                handleGroupSelected={handleGroupSelected}
                getGroupAfter={getGroupAfter}
                displayPublicGroup={displayPublicGroup}
                displayAllGroup={displayAllGroup}
                searchInPlatform={searchInPlatform}
                values={values}
                isMultiple={isMultiple}
                listClassName={pickerClassName}
                groupAll={groupAll}
                groupPublic={groupPublic}
                resultsTextProps={resultsTextProps}
            />
        </AutocompleteComponent>
    );
};
