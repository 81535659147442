import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const videoUsageAdminRoute: Route = {
    path: '/admin/video-usage',
    appId: AppId.backOffice,
};

const videoUsageAdmin = (): Route => videoUsageAdminRoute;

export { videoUsageAdmin, videoUsageAdminRoute };

export const admin = (): Route => {
    return videoUsageAdminRoute;
};
