import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryState {
    /**
     * Whether and error hasBeenCaught or not.
     */
    error?: Error;
}

export interface ErrorBoundaryProps {
    /**
     * The fallback component to show in case of error.
     */
    fallback?: ReactNode | React.FC<{ error: Error }>;
    /**
     * Callback on error.
     * @return `true` if the error is considered as treated. `false` otherwise (activating the fallback rendering).
     */
    onError?(error: Error, info: ErrorInfo): void | true;
}

/**
 * A component that will catch any error
 * thrown by one of its children and that either
 * shows the given fallback component, or nothing
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: undefined };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        const { onError } = this.props;
        const hasError = !onError?.(error, info);
        this.setState({ error: hasError ? error : undefined });
    }

    render() {
        const { error } = this.state;
        const { children, fallback = null } = this.props;
        if (!error) {
            return children;
        }

        if (typeof fallback === 'function') {
            return React.createElement(fallback as React.FC<{ error: Error }>, { error });
        }
        return fallback;
    }
}
