import React, { ReactNode } from 'react';

import { classnames, visuallyHidden } from '@lumapps/classnames';
import { Placement, Popover, PopoverProps, Text, type GenericProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useComboboxRefs } from '../../context/ComboboxRefsContext';
import { useCombobox } from '../../hooks/useCombobox';
import { ComboboxListSkeleton } from './ComboboxListSkeleton';

export interface ComboboxListBoxProps extends GenericProps, React.ComponentProps<'ul'> {
    /** Options display in the combobox */
    children: ReactNode;
    /**
     * Component to use as skeleton for each option instead of the default one.
     * Can either be a react node or a component that receives the index as prop
     */
    renderItemSkeleton?: ReactNode | (({ index }: { index: string }) => ReactNode);
    /** Label for the list */
    label?: string;
    /** Props of the popover element. */
    popoverProps?: Partial<PopoverProps>;
    /**
     * An element to display at the bottom of the listbox.
     * No interactive element must be set here as they will not be accessible.
     */
    footer?: ReactNode;
    /** List ref */
    listRef?: React.Ref<HTMLElement>;
}

/** The states in which the full loading must be displayed */
const FULL_LOADING_STATES = [BaseLoadingStatus.loading, BaseLoadingStatus.debouncing, BaseLoadingStatus.filtering];
const CLASSNAME = 'combobox-listbox';

/**
 * The listbox containing the combobox's options.
 *
 * @family Combobox
 * @param ComboboxListBoxProps
 * @returns ComboboxListBox
 */
export const ComboboxListBox = ({
    children,
    renderItemSkeleton,
    label,
    popoverProps,
    footer,
    listRef,
    ...forwardedProps
}: ComboboxListBoxProps) => {
    const { translateAndReplace, pluralize } = useTranslate();
    const { status, listboxId, isOpen, optionsLength, handleClose: contextHandleClose, type } = useCombobox();
    const { anchorRef } = useComboboxRefs();
    const { onClose, ...otherPopoverProps } = popoverProps || {};
    const { style, className, ...listProps } = forwardedProps;
    // The states to show a full skeleton instead of the options
    const showFullLoading = FULL_LOADING_STATES.includes(status);
    /**
     *  The conditions in which we want to show the content of the popover
     *  * The parent asked for the popover to be opened
     *  * There is at least one option to displayed OR a skeleton
     */
    const showPopover = isOpen && (optionsLength > 0 || showFullLoading);

    const handleClose = () => {
        if (isOpen) {
            contextHandleClose?.();
            onClose?.();
        }
    };

    return (
        <>
            {/* Read changes in available options by a screen reader */}
            <Text as="p" className={visuallyHidden()} role="log" aria-live="assertive">
                {isOpen &&
                    translateAndReplace(pluralize(GLOBAL.NB_OPTIONS_AVAILABLE, optionsLength), { NB: optionsLength })}
            </Text>
            <Popover
                role="none"
                onClose={handleClose}
                anchorRef={anchorRef}
                fitToAnchorWidth
                fitWithinViewportHeight
                closeOnEscape
                closeOnClickAway
                placement={Placement.BOTTOM}
                /**
                 * As the children need to register themselves, we need to leave the popover opened
                 * at all times but hide it when it shouldn't be seen.
                 */
                isOpen
                {...otherPopoverProps}
            >
                <div
                    style={{
                        ...style,
                        overflowY: 'auto',
                        display: showPopover ? 'flex' : 'none',
                        flexDirection: 'column',
                    }}
                >
                    {/**
                     * The DS `List` component has a lot of behavior / default props we do not want here.
                     * So we use a native ul tag.
                     */}
                    <ul
                        ref={listRef as React.Ref<HTMLUListElement>}
                        id={listboxId}
                        role={type}
                        aria-label={label}
                        className={classnames(CLASSNAME, className, 'lumx-list lumx-list--item-padding-big')}
                        style={{
                            flex: 1,
                            overflowY: 'auto',
                        }}
                        {...listProps}
                    >
                        {/* Only show children if a full skeleton isn't already displayed */}
                        {!showFullLoading && children}
                        {[...FULL_LOADING_STATES, BaseLoadingStatus.loadingMore].includes(status) && (
                            <ComboboxListSkeleton isLoadingMore={status === BaseLoadingStatus.loadingMore}>
                                {renderItemSkeleton}
                            </ComboboxListSkeleton>
                        )}
                    </ul>
                    {footer}
                </div>
            </Popover>
        </>
    );
};
