import { useCallback, useContext, useMemo } from 'react';

import { TextFieldProps } from '@lumapps/lumx/react';
import { MovingFocusContext } from '@lumapps/moving-focus';

import { ComboboxContext } from '../context/ComboboxContext';
import { useComboboxRefs } from '../context/ComboboxRefsContext';
import { ComboboxOpenActionPayload, actions } from '../ducks/reducer';
import { ComboboxOptionSelectEventSource, RegisteredComboboxOption } from '../types';
import { isComboboxValue } from '../utils';

/** Retrieve the current combobox state and actions */
export const useCombobox = () => {
    const comboboxContext = useContext(ComboboxContext);
    const { dispatch: movingFocusDispatch } = useContext(MovingFocusContext);
    const { onSelect, onInputChange, onOpen, dispatch, inputValue, ...contextValues } = comboboxContext;
    const { triggerRef } = useComboboxRefs();
    /** Action triggered when the listBox is closed without selecting any option */
    const handleClose = useCallback(() => {
        dispatch(actions.close());
        // Reset visual focus
        movingFocusDispatch({ type: 'RESET_SELECTED_TAB_STOP' });
    }, [dispatch, movingFocusDispatch]);

    /** Callback for when an option is selected */
    const handleSelected = useCallback(
        (option?: RegisteredComboboxOption, source?: ComboboxOptionSelectEventSource) => {
            if (option?.isDisabled) {
                return;
            }

            if (isComboboxValue(option)) {
                handleClose();
                /** Call parent onSelect callback  */
                if (onSelect) {
                    onSelect(option);
                }
            }

            /** If the option itself has a custom action, also call it */
            if (option?.onSelect) {
                option.onSelect(option, source);
            }

            /** Reset focus on input */
            if (triggerRef?.current) {
                triggerRef.current?.focus();
            }
        },
        [handleClose, onSelect, triggerRef],
    );

    /** Callback for when the input must be updated */
    const handleInputChange: TextFieldProps['onChange'] = useCallback(
        (value, ...args) => {
            /** Update the local state */
            dispatch(actions.setInputValue(value));
            /** If a callback if given, call it with the value */
            if (onInputChange) {
                onInputChange(value, ...args);
            }
            /** If the field is emptied, trigger an empty select */
            if (!value) {
                handleSelected();
            }
            // Reset visual focus
            movingFocusDispatch({ type: 'RESET_SELECTED_TAB_STOP' });
        },
        [dispatch, handleSelected, movingFocusDispatch, onInputChange],
    );

    /**
     * Callback for when the listbox is displayed
     *  */
    const handleOpen = useCallback(
        (params: ComboboxOpenActionPayload) => {
            /** update the local state */
            dispatch(actions.open(params));
            /** If a parent callback was given, trigger it with state information */
            if (onOpen) {
                onOpen({ currentValue: inputValue, manual: Boolean(params?.manual) });
            }
        },
        [dispatch, inputValue, onOpen],
    );

    return useMemo(
        () => ({
            handleClose,
            handleOpen,
            handleInputChange,
            handleSelected,
            dispatch,
            inputValue,
            ...contextValues,
        }),
        [contextValues, dispatch, handleClose, handleInputChange, handleOpen, handleSelected, inputValue],
    );
};
