import React from 'react';

import groupBy from 'lodash/groupBy';

import { isComponentType } from '@lumapps/utils/types/isComponentType';

import { Combobox } from '../components/Combobox';
import type { BaseSelectProps } from '../types';
import { getWithSelector } from './getWithSelector';

/** Render options & sections */
export function renderSelectOptions<O>(props: BaseSelectProps<O>) {
    // Render sections
    if (props.getSectionId) {
        const { options, getSectionId: optionSectionSelector, ...forwardedProps } = props;
        // Group options by sections
        return Object.entries(groupBy(options, optionSectionSelector)).map(([title, options]) => (
            <Combobox.Section key={title} title={title} withDivider={props.hasSectionDividers}>
                {renderSelectOptions({ options, ...forwardedProps })}
            </Combobox.Section>
        ));
    }

    const { options, getOptionId: optionIdSelector, getOptionName: optionNameSelector, renderOption } = props;

    // Render options
    return options?.map((item) => {
        const id = getWithSelector(optionIdSelector || optionNameSelector, item);
        const name = getWithSelector(optionNameSelector || optionIdSelector, item);

        // Custom option render
        if (renderOption) {
            const res = renderOption(item);
            if (isComponentType(Combobox.Option, res)) {
                // Override id & textValue
                const { children, ...props } = res.props;
                return (
                    <Combobox.Option {...props} key={id} id={id} textValue={name}>
                        {children}
                    </Combobox.Option>
                );
            }
            // Invalid custom render
            return null;
        }

        // Fallback to name
        return (
            <Combobox.Option key={id} id={id} textValue={name}>
                {name}
            </Combobox.Option>
        );
    });
}
