import React from 'react';

import { ArticlePickerDialog } from '@lumapps/article-pickers/components/ArticlePickerDialog';
import type { Article } from '@lumapps/articles/types';
import type { Community } from '@lumapps/communities/types';
import { CommunityPickerDialog } from '@lumapps/community-pickers/components/CommunityPickerDialog';
import { useCommunityListPicker } from '@lumapps/community-pickers/hooks/useCommunityListPicker';
import { ContentPickerDialog } from '@lumapps/content-pickers/components/ContentPickerDialog';
import type { Content } from '@lumapps/contents/types';
import { EventPickerDialog } from '@lumapps/event-pickers/components/EventPickerDialog';
import type { Event } from '@lumapps/events/types';
import { currentLanguageSelector } from '@lumapps/languages';
import { TrainingPickerDialog } from '@lumapps/learning-pickers/components/TrainingPickerDialog';
import type { Training } from '@lumapps/learning-trainings/types';
import type { InstalledExtensionAPIProps } from '@lumapps/marketplace/legacy-types';
import { MicroAppPickerDialog } from '@lumapps/micro-app-pickers/components/MicroAppPickerDialog';
import { PlaylistPickerDialog } from '@lumapps/play-pickers/components/PlaylistPickerDialog';
import { VideoPickerDialog } from '@lumapps/play-pickers/components/VideoPickerDialog';
import type { Playlist } from '@lumapps/play-playlists/types';
import type { Video } from '@lumapps/play/api/types';
import { PostPickerDialog } from '@lumapps/post-pickers/components/PostPickerDialog';
import type { Post } from '@lumapps/posts/types';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../../../constants';
import type { LinkPreviewAttributes } from '../../../types';
import {
    mapPickerArticleToResourcePreview,
    mapPickerCommunityToResourcePreview,
    mapPickerContentToResourcePreview,
    mapPickerEventToResourcePreview,
    mapPickerLearningToResourcePreview,
    mapPickerMicroAppToResourcePreview,
    mapPickerPlaylistToResourcePreview,
    mapPickerPostToResourcePreview,
    mapPickerVideoToResourcePreview,
} from '../../../utils/mapPickers';

export interface ReplaceResourcePreviewDialogProps {
    /** whether the dialog is open or not. */
    isOpen: boolean;
    /** callback to be executed when the dialog is closed */
    onClose: () => void;
    /** callback to be executed once the preview has been changed */
    onChange: (preview: LinkPreviewAttributes) => void;
    /** ref of parent element to make keyboard navigation easy */
    parentElement: React.RefObject<any>;
    /** resource preview type */
    resourceType: LINK_PREVIEW_RESOURCE_TYPES | undefined;
}

/**
 * Opens a resource corresponding dialog when clicking on replace button, to pick a new resource
 * @param ReplaceResourcePreviewDialogProps
 * @returns ReplaceResourcePreviewDialog
 */
export const ReplaceResourcePreviewDialog: React.FC<ReplaceResourcePreviewDialogProps> = ({
    isOpen,
    onClose,
    onChange,
    parentElement,
    resourceType,
}) => {
    const t = useTranslate();
    const { translateObject } = t;

    const currentLanguage = useSelector(currentLanguageSelector);

    switch (resourceType) {
        case LINK_PREVIEW_RESOURCE_TYPES.ARTICLE:
            return (
                <ArticlePickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={(articles: Article[]) => {
                        if (articles.length) {
                            onChange(mapPickerArticleToResourcePreview(articles[0], translateObject, currentLanguage));
                        }
                        onClose();
                    }}
                    context="platform"
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY: {
            const fetchCommunityParams = {
                // fields params overrides for fetching community to add instanceDetails
                fields: 'items(id,uid,title,description,renderingType,privacy,slug,thumbnail,customer,instance,instanceDetails,feedKeys),more,cursor',
            };
            return (
                <CommunityPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={(communities: Community[]) => {
                        if (communities.length) {
                            onChange(
                                mapPickerCommunityToResourcePreview(communities[0], translateObject, currentLanguage),
                            );
                        }
                        onClose();
                    }}
                    fetchParams={fetchCommunityParams}
                    communityPickerHook={useCommunityListPicker}
                />
            );
        }

        case LINK_PREVIEW_RESOURCE_TYPES.CONTENT: {
            const fetchContentParams = {
                // fields params overrides for fetching content, so we get the excerpt
                fields: 'items(id,title,slug,type,customContentType,mediaThumbnail,feedKeys,createdAt,instance,excerpt),more,cursor',
            };
            return (
                <ContentPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={(contents: Content[]) => {
                        if (contents.length) {
                            onChange(mapPickerContentToResourcePreview(contents[0], translateObject, currentLanguage));
                        }
                        onClose();
                    }}
                    fetchParamsOverrides={fetchContentParams}
                />
            );
        }

        case LINK_PREVIEW_RESOURCE_TYPES.EVENT:
            return (
                <EventPickerDialog
                    isOpen={isOpen}
                    //! FIXME: we should NOT restrict to current instance but picker does not provide event's instance so, for now, it's safer this way
                    onlyUseCurrentInstance
                    onCancel={() => onClose()}
                    onConfirm={(events: Event[]) => {
                        if (events.length) {
                            onChange(mapPickerEventToResourcePreview(events[0], translateObject, currentLanguage));
                        }
                        onClose();
                    }}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE:
        case LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH:
            return (
                <TrainingPickerDialog
                    isOpen={
                        isOpen &&
                        (resourceType === LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH ||
                            resourceType === LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE)
                    }
                    onCancel={() => onClose()}
                    onConfirm={(learning: Training[]) => {
                        if (learning.length) {
                            onChange(mapPickerLearningToResourcePreview(learning[0]));
                        }
                        onClose();
                    }}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP:
            return (
                <MicroAppPickerDialog
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={(microApps: InstalledExtensionAPIProps[]) => {
                        if (microApps.length) {
                            onChange(
                                mapPickerMicroAppToResourcePreview(microApps[0], translateObject, currentLanguage),
                            );
                        }
                        onClose();
                    }}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO:
            return (
                <VideoPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onSelectionCancel={() => onClose()}
                    onSelectionConfirm={(videos?: Video[]) => {
                        if (videos?.length) {
                            onChange(mapPickerVideoToResourcePreview(videos[0], translateObject, currentLanguage));
                        }
                        onClose();
                    }}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST:
            return (
                <PlaylistPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onSelectionCancel={() => onClose()}
                    onSelectionConfirm={(playlists?: Playlist[]) => {
                        if (playlists?.length) {
                            onChange(
                                mapPickerPlaylistToResourcePreview(playlists[0], translateObject, currentLanguage),
                            );
                        }
                        onClose();
                    }}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.POST:
            return (
                <PostPickerDialog
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={(posts: Post[]) => {
                        if (posts.length) {
                            onChange(mapPickerPostToResourcePreview(posts[0], t, currentLanguage));
                        }
                        onClose();
                    }}
                    params={{ excerptFormat: 'PLAIN_TEXT' }}
                />
            );

        default:
            return null;
    }
};
