import { useMemo } from 'react';

import concat from 'lodash/fp/concat';
import flatten from 'lodash/fp/flatten';
import get from 'lodash/fp/get';
import intersection from 'lodash/fp/intersection';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import reject from 'lodash/fp/reject';

import { ItemVariant } from '@lumapps/lumx-navigation/constants';
import type { SpaceServices } from '@lumapps/spaces/types';
import evolve from '@lumapps/utils/function/evolve';

import { SPACE_NAV_V1_STATIC_ITEMS, SPACE_NAV_V2_EXPLORE_ITEMS, SPACE_NAV_V2_STATIC_ITEMS } from '../constants';
import { servicesToNavigationItems } from '../utils';

export interface UseSpaceNavigationItemsProps {
    id: string;
    slug: string;
    enabledServices: SpaceServices[];
    isV2: boolean;
}

export const useSpaceNavigationItems = ({ id, slug, enabledServices, isV2 }: UseSpaceNavigationItemsProps) =>
    useMemo(() => {
        const staticServices = intersection(
            enabledServices,
            isV2 ? SPACE_NAV_V2_STATIC_ITEMS : SPACE_NAV_V1_STATIC_ITEMS,
        );

        const exploreServices: SpaceServices[] = pipe(
            map(get('services')),
            flatten,
            intersection(enabledServices),
        )(isV2 ? SPACE_NAV_V2_EXPLORE_ITEMS : []);

        const staticItems = servicesToNavigationItems(
            id,
            slug,
            isV2 ? ItemVariant.iconOnly : ItemVariant.default,
            staticServices,
        );

        // if the explore menu has more than 1 item, compute its items
        const exploreItems =
            exploreServices.length > 1
                ? pipe(
                      map(
                          evolve({
                              services: pipe(
                                  intersection(enabledServices),
                                  servicesToNavigationItems(id, slug, undefined),
                              ),
                          }),
                      ),
                      reject(pipe(get('services'), isEmpty)),
                  )(SPACE_NAV_V2_EXPLORE_ITEMS)
                : undefined;

        // else compute a single item that will be added to static ones
        const singleItem =
            exploreServices.length === 1
                ? servicesToNavigationItems(id, slug, ItemVariant.default, exploreServices)
                : [];

        return { staticItems: concat(staticItems, singleItem), exploreItems };
    }, [enabledServices, id, isV2, slug]);
