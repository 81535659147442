import React, { useMemo } from 'react';

import { InsertLinkDialog } from '@lumapps/lumx-preview/components/InsertUrlPreviewDialog';
import { mdiLinkBoxVariantOutline } from '@lumapps/lumx/icons';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { LinkAttachment } from '@lumapps/widget-base/types';
import { ToolbarItem } from '@lumapps/wrex/types';

import { LINK_PREVIEW } from '../../../constants';

/**
 * Hook to make linkPreviewButton properties reusable from specific component as dropdown (ex: in structured content toolbar)
 */
export const useLinkPreviewButton = (
    onInsert: (link: LinkAttachment) => void,
    otherProps?: any,
    className?: string,
) => {
    const { translateKey } = useTranslate();
    const [isLinkDialogOpen, , closeLinkDialog, openLinkDialog] = useBooleanState(false);
    const linkPreviewButton = useMemo(() => {
        const onInsertLink = (link: LinkAttachment) => {
            onInsert(link);

            closeLinkDialog();
        };

        const insertLinkDialog = (
            <InsertLinkDialog
                className={className}
                isOpen={isLinkDialogOpen}
                onInsert={onInsertLink}
                onClose={closeLinkDialog}
                // check SUP-5761 to get why we added this: https://lumapps.atlassian.net/browse/SUP-5761
                shouldPreventRedirectOnValidUrl
            />
        );

        const linkPreviewButtonProps: ToolbarItem = {
            itemKey: LINK_PREVIEW,
            icon: mdiLinkBoxVariantOutline,
            label: translateKey(GLOBAL.LINK_PREVIEW),
            otherProps,
            onClick: openLinkDialog,
            type: 'option',
        };

        return { insertLinkDialog, linkPreviewButtonProps };
    }, [className, isLinkDialogOpen, closeLinkDialog, translateKey, otherProps, openLinkDialog, onInsert]);

    return linkPreviewButton;
};
