/* eslint-disable react/forbid-elements */
import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { getCurrentCustomContentTypeId } from '@lumapps/contents/ducks/selectors';
import { sendTrackingDirectoryEntryClickActionEvent } from '@lumapps/directories/api/analytics';
import { DirectoryEntryCreationDialog } from '@lumapps/directory-entries/components/DirectoryEntryCreationDialog';
import { useEntryLink } from '@lumapps/directory-entries/hooks/useEntryLink';
import { ConfirmDialog } from '@lumapps/lumx-dialogs/components/ConfirmDialog';
import { ContextMenu } from '@lumapps/lumx-menu/components/ContextMenu';
import { MenuOption } from '@lumapps/lumx-menu/components/ContextMenu/types';
import { ColorBubbleLetter } from '@lumapps/lumx-texts/components/ColorBubbleLetter';
import { mdiStar, mdiStarOutline } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    ColorPalette,
    ColorVariant,
    Emphasis,
    IconButton,
    Link,
    ThumbnailProps,
    ThumbnailVariant,
    Theme,
    Text,
    Typography,
} from '@lumapps/lumx/react';
import { MetadataLinkList } from '@lumapps/metadata-link/components/MetadataLinkList';
import { MetadataListV2 } from '@lumapps/metadata/types';
import { useSelector } from '@lumapps/redux/react';
import { isExternalUrl } from '@lumapps/router/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { hashCode } from '@lumapps/utils/string/hashCode';
import { BlockImage } from '@lumapps/widget-base/components/Block/BlockImage';
import { BlockComponent } from '@lumapps/widget-base/types';

import { useDeletedUserDirectoryEntry } from '../../hooks/useDeleteUserDirectoryEntry';
import { useSaveUserDirectoryEntry } from '../../hooks/useSaveUserDirectoryEntry';
import { WIDGET_DIRECTORY_ENTRIES } from '../../keys';
import { BlockDirectoryEntry as BlockDirectoryEntry1, BlockDirectoryEntryVariant } from '../../types';

import './index.scss';

const Config = get();

interface BlockDirectoryEntryProps extends BlockDirectoryEntry1 {
    favorite?: () => void;
    unfavorite?: () => void;
}

export const CLASSNAME = 'directory-entry-block';

export const BlockDirectoryEntry: BlockComponent<BlockDirectoryEntryProps> = ({
    additionalFields,
    directoryEntryId,
    directoryId,
    title,
    tags,
    metadata,
    link,
    image,
    isFavorite,
    isFavoriteIconVisible,
    variant = BlockDirectoryEntryVariant.horizontal,
    favorite,
    unfavorite,
    canEdit = false,
    canDelete = false,
    theme = Theme.light,
    widget,
}) => {
    const { translateKey } = useTranslate();
    const contentTypeId = useSelector(getCurrentCustomContentTypeId);
    const { element, block } = useClassnames(CLASSNAME);
    const { openSaveDialog, creationDialogProps } = useSaveUserDirectoryEntry(widget, directoryId);
    const { deleteDialogProps, openDeleteDialog } = useDeletedUserDirectoryEntry(widget, directoryEntryId);

    const thumbnailProps: Partial<ThumbnailProps> = {
        // Set an empty alt as it's redundant of the title.
        alt: '',
        variant: ThumbnailVariant.squared,
        aspectRatio: AspectRatio.square,
    };

    const href = useEntryLink(link) || undefined;

    const linkProps = useMemo(
        () => ({
            href,
            target: isExternalUrl(href) ? '_blank' : '_self',
            rel: isExternalUrl(href) ? 'noopener noreferrer' : undefined,
        }),
        [href],
    );

    // Metadata needs to be formaded to type MetadataListV2 in order to be used with MetadataLinkList component
    const metadataList: MetadataListV2 | undefined = metadata?.map(({ metadataId, name }) => ({
        name,
        id: metadataId,
    }));

    const trackClickActions = () =>
        sendTrackingDirectoryEntryClickActionEvent({
            targetId: directoryEntryId,
            url: link,
            title,
            thumbnail: image?.url,
        });

    const menuOptions = useMemo(() => {
        const options: MenuOption[] = [];

        if (canEdit) {
            options.push({
                type: 'option',
                labelKey: translateKey(GLOBAL.EDIT),
                onSelect: () => {
                    openSaveDialog(directoryEntryId);
                },
            });
        }

        if (canDelete) {
            options.push({
                type: 'option',
                labelKey: translateKey(GLOBAL.DELETE),
                onSelect: openDeleteDialog,
            });
        }

        return options;
    }, [canEdit, canDelete, translateKey, openSaveDialog, directoryEntryId, openDeleteDialog]);

    return (
        <div
            key={directoryEntryId}
            data-testid="container-directory-entry"
            className={block({
                [`theme-${theme}`]: true,
                horizontal: variant === BlockDirectoryEntryVariant.horizontal,
                vertical: variant === BlockDirectoryEntryVariant.vertical,
            })}
        >
            {/**
             * Hide thumbnail from screen readers and remove focus from it as it is only decorative.
             * As the title itself is the same link, this element is only useful for mouse users that
             * want to click on the icon instead of the title.
             */}
            <a className={element('thumbnail-wrapper')} tabIndex={-1} aria-hidden="true" {...linkProps}>
                {image ? (
                    <BlockImage {...image} theme={theme} thumbnailProps={thumbnailProps} />
                ) : (
                    <ColorBubbleLetter
                        className={element('letter')}
                        text={title || link}
                        colors={Config.defaultColors}
                        size={thumbnailProps.size}
                    />
                )}
            </a>

            <div className={element('content')}>
                <Link className={element('title')} {...linkProps} onClick={trackClickActions}>
                    {title || link}
                </Link>

                {!!tags?.items?.length && (
                    <div className={element('tags')}>
                        {tags.items.map((tag) => (
                            <span key={tag.tagId}>
                                <span>{tag.name}</span>
                            </span>
                        ))}
                    </div>
                )}

                {additionalFields &&
                    additionalFields
                        .flatMap(({ values }) => values)
                        .map((value) => (
                            <Text
                                key={hashCode(value)}
                                className={element('custom-field')}
                                typography={Typography.body1}
                                color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                                colorVariant={ColorVariant.L2}
                                as="span"
                            >
                                {value}
                            </Text>
                        ))}

                <MetadataLinkList metadataList={metadataList} theme={theme} contentTypeId={contentTypeId || ''} />
            </div>

            {(canEdit || canDelete) && (
                <>
                    <ContextMenu
                        theme={theme}
                        menuOptions={menuOptions}
                        scope={`directory-entry-${directoryEntryId}`}
                    />
                    <DirectoryEntryCreationDialog {...creationDialogProps} />
                    <ConfirmDialog
                        {...deleteDialogProps}
                        confirmMessage={
                            <Text as="p" typography={Typography.body1}>
                                {deleteDialogProps.confirmMessage}
                            </Text>
                        }
                    />
                </>
            )}

            {isFavoriteIconVisible && (
                <div className={element('actions')}>
                    <IconButton
                        emphasis={Emphasis.low}
                        icon={isFavorite ? mdiStar : mdiStarOutline}
                        onClick={isFavorite ? unfavorite : favorite}
                        theme={theme}
                        label={translateKey(WIDGET_DIRECTORY_ENTRIES.FAVORITE_BUTTON_ARIA)}
                        aria-pressed={isFavorite}
                    />
                </div>
            )}
        </div>
    );
};

BlockDirectoryEntry.displayName = 'BlockDirectoryEntry';
