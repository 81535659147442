import React from 'react';

import { MovingFocusContext } from '@lumapps/moving-focus';

import { RegisteredComboboxOptionValue } from '../types';
import { isComboboxValue } from '../utils';
import { UseComboboxTriggerArgs, useComboboxTrigger } from './useComboboxTrigger';

/** Is printable character key press */
const isTypeEvent = ({ key, altKey, ctrlKey, metaKey }: React.KeyboardEvent) =>
    key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey;

/**
 * Provide props for the semantic and behaviors the combobox button trigger
 *
 * Overrides the useComboboxTrigger() props & behavior to add a jump to option
 * on printable character key down.
 */
export function useComboboxButton(args: UseComboboxTriggerArgs) {
    const {
        context: { isOpen, handleOpen, options },
        onKeyDown: parentOnKeyDown,
    } = args;
    const { dispatch: movingFocusDispatch } = React.useContext(MovingFocusContext);

    const openIfClosed = React.useCallback(() => {
        if (!isOpen) {
            handleOpen({ manual: false });
        }
    }, [isOpen, handleOpen]);

    const onKeyDown = React.useMemo<React.KeyboardEventHandler>(
        () => {
            let searchValue = '';
            let previousSearchIndex = 0;
            let searchTimeout: ReturnType<typeof setTimeout> | undefined;

            function clearSearchTimeout() {
                if (!searchTimeout) {
                    return;
                }
                clearTimeout(searchTimeout);
                searchTimeout = undefined;
            }
            function clearSearch() {
                searchValue = '';
                clearSearchTimeout();
            }

            return (event) => {
                if (!isTypeEvent(event)) {
                    // Forward key down event
                    parentOnKeyDown?.(event);
                    return;
                }
                event.stopPropagation();

                // Open combobox
                openIfClosed();

                // Clear current search timeout
                clearSearchTimeout();

                // Append key to current search
                searchValue += event.key.toLowerCase();

                // Clear search after 500ms
                searchTimeout = setTimeout(clearSearch, 500);

                // Search is containing all the same letters (ex: aaaa)
                const allTheSameLetters = searchValue.split('').every((letter) => letter === searchValue[0]);

                let matchingOption: RegisteredComboboxOptionValue<any> | undefined;
                const optionArray = Object.values(options);

                // If search is all the same letters, start from last search
                const startIndex = previousSearchIndex;
                let index = startIndex;
                do {
                    // Increment index and loop around to 0 if we get over the array length
                    index = (index + 1) % optionArray.length;
                    const option = optionArray[index];
                    // Search by text value or fallback on id.
                    const optionText = isComboboxValue(option) ? option?.textValue || option?.id : null;
                    if (isComboboxValue(option) && optionText) {
                        const optionTextValue = optionText.toLowerCase();

                        // Search on first letter if search is all the same letters
                        const searchText = allTheSameLetters ? searchValue[0] : searchValue;

                        // Option text value starts with searched text
                        if (optionTextValue.startsWith(searchText)) {
                            previousSearchIndex = index;
                            matchingOption = option;
                            break;
                        }
                    }
                } while (index !== startIndex);

                if (matchingOption) {
                    movingFocusDispatch({
                        type: 'SELECT_TAB_STOP',
                        payload: {
                            id: matchingOption.generatedId,
                            type: 'keyboard',
                        },
                    });
                } else {
                    clearSearch();
                }
            };
        },
        // Excluding openIfClosed as we don't want to clear the search on open
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options, parentOnKeyDown, movingFocusDispatch],
    );

    return useComboboxTrigger({ ...args, onKeyDown });
}
