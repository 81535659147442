export enum CONTENT_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=518117304
     */
    ALL_CONTENT = 'CONTENT.ALL_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523157059
     */
    ALL_CONTENT_SELECTED = 'CONTENT_ADMIN.ALL_CONTENT_SELECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=528919815
     */
    EXPIRES_IN_DATE = 'CONTENT_ADMIN.EXPIRES_IN_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523835825
     */
    EXPIRES_ON_DATE = 'CONTENT_ADMIN.EXPIRES_ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530485220
     */
    DISABLED_INLINE_ACTION_TOOLTIP = 'CONTENT_ADMIN.DISABLED_INLINE_ACTION_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=524511039
     */
    NO_CONTENT_MESSAGE = 'CONTENT_ADMIN.NO_CONTENT_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=524500748
     */
    NO_CONTENT_TITLE = 'CONTENT_ADMIN.NO_CONTENT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=524173326
     */
    NUMBER_CONTENT = 'CONTENT_ADMIN.NUMBER_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=527080831
     */
    NUMBER_CONTENT_OUT_OF_MANY = 'CONTENT_ADMIN.NUMBER_CONTENT_OUT_OF_MANY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=527080956
     */
    NUMBER_CONTENT_OUT_OF_MANY_SELECTED = 'CONTENT_ADMIN.NUMBER_CONTENT_OUT_OF_MANY_SELECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523160370
     */
    NUMBER_CONTENT_SELECTED = 'CONTENT_ADMIN.NUMBER_CONTENT_SELECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530408160
     */
    SELECT_A_PERSON = 'CONTENT_ADMIN.SELECT_A_PERSON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530839609
     */
    CONFIRM_CHANGES = 'CONTENT_ADMIN.CONFIRM_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532218335
     */
    CHANGES_MAY_TAKE_A_FEW_MINUTES = 'CONTENT_ADMIN.CHANGES_MAY_TAKE_A_FEW_MINUTES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532220449
     */
    CHANGES_OVERVIEW = 'CONTENT_ADMIN.CHANGES_OVERVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532255290
     */
    MULTIPLE_CONTENT_UPDATED = 'CONTENT_ADMIN.MULTIPLE_CONTENT_UPDATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532257087
     */
    UNABLE_TO_UPDATE_MUTIPLE_CONTENT = 'CONTENT_ADMIN.UNABLE_TO_UPDATE_MUTIPLE_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532260230
     */
    ONLY_ONE_BULK = 'CONTENT_ADMIN.ONLY_ONE_BULK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532260394
     */
    BULK_STARTED_BY_SOMEONE = 'CONTENT_ADMIN.BULK_STARTED_BY_SOMEONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532275226
     */
    REFRESH_TO_CHECK_STATUS = 'CONTENT_ADMIN.REFRESH_TO_CHECK_STATUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532288917
     */
    CHANGES_OVERVIEW_AUTHOR = 'CONTENT_ADMIN.CHANGES_OVERVIEW_AUTHOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532659874
     */
    BULK_IN_PROGRESS = 'CONTENT_ADMIN.BULK_IN_PROGRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532659887
     */
    BULK_ERROR = 'CONTENT_ADMIN.BULK_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=532673092
     */
    BULK_DONE = 'CONTENT_ADMIN.BULK_DONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533218889
     */
    ERROR_IN_EDITING_OPTIONS = 'CONTENT_ADMIN.ERROR_IN_EDITING_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533218917
     */
    ERROR_IN_EDITING_OPTIONS_DESCRIPTION = 'CONTENT_ADMIN.ERROR_IN_EDITING_OPTIONS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533486610
     */
    ANOTHER_BULK_ACTION_IN_PROGRESS_TITLE = 'CONTENT_ADMIN.ANOTHER_BULK_ACTION_IN_PROGRESS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533486848
     */
    ANOTHER_BULK_ACTION_IN_PROGRESS_DESCRIPTION = 'CONTENT_ADMIN.ANOTHER_BULK_ACTION_IN_PROGRESS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536780811
     */
    EXPIRATION_DATE_FIELD_HELPER = 'CONTENT_ADMIN.EXPIRATION_DATE_FIELD_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536812128
     */
    CHANGES_OVERVIEW_EXPIRATION_DATE = 'CONTENT_ADMIN.CHANGES_OVERVIEW_EXPIRATION_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=537656077
     */
    CONFIRM_UNPUBLISH = 'CONTENT_ADMIN.CONFIRM_UNPUBLISH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=537656580
     */
    CONFIRM_PUBLISH = 'CONTENT_ADMIN.CONFIRM_PUBLISH',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452686
     */
    UNPUBLISH = 'CONTENT_SET_TO_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540213650
     */
    SOME_CONTENT_MAY_BE_IGNORED = 'CONTENT_ADMIN.SOME_CONTENT_MAY_BE_IGNORED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540213694
     */
    TO_REVERSE_UNPUBLISH = 'CONTENT_ADMIN.TO_REVERSE_UNPUBLISH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540213722
     */
    EXPLAIN_UNPUBLISH_MULTIPLE = 'CONTENT_ADMIN.EXPLAIN_UNPUBLISH_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540218486
     */
    EXPLAIN_UNPUBLISH_ONE = 'CONTENT_ADMIN.EXPLAIN_UNPUBLISH_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540238250
     */
    EXPLAIN_PUBLISH_MULTIPLE = 'CONTENT_ADMIN.EXPLAIN_PUBLISH_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540238393
     */
    EXPLAIN_PUBLISH_ONE = 'CONTENT_ADMIN.EXPLAIN_PUBLISH_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540239547
     */
    TO_REVERSE_PUBLISH = 'CONTENT_ADMIN.TO_REVERSE_PUBLISH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533475153
     */
    DISABLED_WHILE_RUNNING_TOOLTIP = 'CONTENT_ADMIN.DISABLED_WHILE_RUNNING_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540554292
     */
    CONFIRM_UNARCHIVE = 'CONTENT_ADMIN.CONFIRM_UNARCHIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540554629
     */
    EXPLAIN_UNARCHIVE_ONE = 'CONTENT_ADMIN.EXPLAIN_UNARCHIVE_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540554726
     */
    EXPLAIN_UNARCHIVE_MULTIPLE = 'CONTENT_ADMIN.EXPLAIN_UNARCHIVE_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540627240
     */
    CONFIRM_ARCHIVE = 'CONTENT_ADMIN.CONFIRM_ARCHIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540627266
     */
    EXPLAIN_ARCHIVE_MULTIPLE = 'CONTENT_ADMIN.EXPLAIN_ARCHIVE_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540627279
     */
    EXPLAIN_ARCHIVE_ONE = 'CONTENT_ADMIN.EXPLAIN_ARCHIVE_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540627366
     */
    TO_REVERSE_ARCHIVE = 'CONTENT_ADMIN.TO_REVERSE_ARCHIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=541651151
     */
    INCOMPATIBLE_ACTION = 'CONTENT_ADMIN.INCOMPATIBLE_ACTION',
}
